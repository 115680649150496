import { render, staticRenderFns } from "./RateManagementByProperty.vue?vue&type=template&id=1681ea20&scoped=true&"
import script from "./RateManagementByProperty.vue?vue&type=script&lang=js&"
export * from "./RateManagementByProperty.vue?vue&type=script&lang=js&"
import style0 from "./RateManagementByProperty.vue?vue&type=style&index=0&id=1681ea20&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1681ea20",
  null
  
)

export default component.exports