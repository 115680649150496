<template>
  <div class="rm-section-content">
    <div
      class="mt-4"
      v-if="notification && property && isRateManagementEnabled"
    >
      <hb-notification
        v-model="notification"
        title="Note:"
        type="caution"
        @close="setHeight"
      >
        <span v-html="notificationMessage"></span>
      </hb-notification>
    </div>
    <div
      :style="{ height: reportHeight + 'px' }"
      v-resize="setHeight"
      class="mb-3"
    >
      <HummingbirdReportViewer
        v-if="property && isRateManagementEnabled"
        :show_divider="false"
        :show_default_report_option="true"
        :show_property_selector="false"
        :grid-conf="gridConf"
        :column-conf="columnConf"
        left_slot
        report_type="property_rate_management"
        right_click
        @rowClicked="_setSpaceData($event)"
        class="rate-management-by-property-bi"
        @gridReady="(api) => (gridApi = api)"
      >
        <template v-slot:left>
          <span v-if="!defaultGroupingProfile">
            <v-skeleton-loader
              max-width="70%"
              type="text"
              class="profile-loader"
            />
            <!--default will be 5-->
          </span>
          <div v-else class="hb-font-header-3-medium pt-2">
            Grouping Profile:
            <span :class="{ none: !defaultGroupingProfile }">{{
              defaultGroupingProfile || "None"
            }}</span>
          </div>
        </template>
      </HummingbirdReportViewer>

      <!-- EMPTY SCREEN STARTS -->
      <EmptyStateContainer
        class="my-3"
        v-show="showPlaceholder"
        :message="placeholderText"
      />
      <!-- EMPTY SCREEN ENDS -->

      <!-- Disable rate management profile -->
      <ConfirmationPopup v-once ref="confirmationPopup" />
      <!-- Disable rate management profile -->

      <AssignRatePlan ref="assignRatePlan" />

      <ManagePromos ref="managePromos" :property="property" />
    </div>
  </div>
</template>

<script type="text/babel">
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { formatMoneyByCurrency } from "@/utils/common";

import HummingbirdReportViewer from "../../assets/HummingbirdReportViewer.vue";
import RateManagementByPropertyActions from "./RateManagementByPropertyActions.vue";
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import AssignRatePlan from "./AssignRatePlan.vue";
import ManagePromos from "./ManagePromos.vue";

import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import BooleanCellRenderer from "@/components/BI/BooleanCellRenderer";
import PlanCellRenderer from "@/components/BI/PlanCellRenderer";
import { startCase } from "lodash";
import api from "../../../assets/api.js";

function reduceRowData(rowData, mapping) {
  let formaters = {
    dollar: (val) => `\$${Number(val).toFixed(2)}`,
    percentage: (val) => `${(Number(val) * 100).toFixed(2)}%`,
  };
  return Object.entries(mapping).map(([key, obj]) => {
    let formattedValue = formaters[obj.type]
      ? formaters[obj.type](rowData[key])
      : rowData[key];
    if (key === "spacegroup_variance_percentage") {
      formattedValue =
        formatMoneyByCurrency(
          Number(rowData["spacegroup_variance_amount"]),
          2
        ) +
        " / " +
        formattedValue;
    }
    return {
      label: obj.label,
      value: formattedValue,
    };
  });
}

export default {
  data() {
    return {
      loading: false,
      gridApi: null,
      reportHeight: 0,
      notification: true,
    };
  },
  props: {
    hasPropertySelector: {
      type: Boolean,
      default: true,
    },
    previousSpaceGroupHash: {
      type: String,
      default: "",
    },
  },
  components: {
    HummingbirdReportViewer,
    EmptyStateContainer,
    ConfirmationPopup,
    AssignRatePlan,
    MultiLineRenderer,
    BooleanCellRenderer,
    PlanCellRenderer,
    ManagePromos,
  },
  watch: {
    property: {
      handler(prev, next) {
        // Let it run on background
        this.setRestGridEnableAction(true);
        this.fetchProfileAndConfigurations();
      },
      immediate: true,
    },
    previousSpaceGroupHash: {
      handler(prev, next) {
        if (this.previousSpaceGroupHash)
          this.updateRowData(this.previousSpaceGroupHash);
      },
      immediate: true,
    },
  },
  mixins: [notificationMixin],
  mounted() {
    let events = {
      toggleProfileStatus: this.toggleProfileStatus,
      assignRatePlan: this.assignRatePlan,
      refreshTable: this.triggerTableRefresh,
      managePromos: this.managePromos,
    };
    EventBus.$on("rateManagementByPropertyEvent", ({ event, data }) =>
      events[event](data)
    );
  },
  computed: {
    ...mapGetters({
      getStructure: "reportStore/getStructure",
      getPropertyRatePlanConf: "revManStore/getPropertyRatePlanConf",
      property: "revManStore/getSelectedProperty",
      isRateManagementEnabled: "revManStore/isRateManagementEnabled",
      defaultGroupingProfile: "revManStore/getDefaultGroupingProfile",
      hasInternalRateEngine: "revManStore/hasInternalRateEngine",
      rateEngine: "revManStore/rateEngine",
    }),
    ...mapState({
      ratePlans: (state) => state.revManStore.rate.plans ?? [],
    }),
    notificationMessage() {
      return this.hasInternalRateEngine
        ? "Changes made to any Rate Plan will only take effect after midnight."
        : `Since you are using <strong>${startCase(
            this.rateEngine
          )}</strong>, you cannot update the Rate Plans`;
    },
    tableData() {
      const TEMPLATE = "property_rate_management";
      let { views = [] } = this.getStructure(TEMPLATE) ?? {};
      return views.find((view) => view.template === TEMPLATE)?.table_data ?? [];
    },
    gridConf() {
      return {
        props: {
          BIHelpers: {
            kebab: {
              enabled: true,
              icon: "mdi-dots-vertical",
              component: {
                definition: RateManagementByPropertyActions,
                props: {
                  propertyId: this.property,
                },
              },
              action: "click",
            },
          },
          getRowId: (params) => params.data.spacegroup_id_hash,
          // animateRows: true,
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          getRowHeight: (params) =>
            params?.data?.spacegroup_summary ? 60 : 48,
          // pagination: false
        },
      };
    },
    columnConf() {
      return {
        spacegroup_rate_management_active: {
          cellRenderer: "BooleanCellRenderer",
          pinned: "right",
          lockPosition: "right",
          suppressNavigable: true,
          hide: !this.hasInternalRateEngine,
          cellRendererParams: {
            icon: true,
          },
          cellClass: "boolean-icons",
        },
        spacegroup_rate_plan_name: {
          pinned: "right",
          lockPosition: "right",
          suppressNavigable: true,
          cellRenderer: "PlanCellRenderer",
          hide: !this.hasInternalRateEngine,
          // Pass from which keys info is extracted
          cellRendererParams: {
            type: "rate",
            unit_type_key: "spacegroup_category",
            price_delta_type_key: "spacegroup_rate_plan_change_type",
          },
        },
        spacegroup_spacetype: {
          autoWidth: true,
          pinned: "left",
        },
        spacegroup_size: {
          autoWidth: true,
          pinned: "left",
        },
        spacegroup_amenities: {
          autoWidth: true,
          pinned: "left",
        },
      };
    },
    /**
     * Hide/show empty state container according return value
     * @returns {Boolean} A boolean value
     */
    showPlaceholder() {
      return !this.property || !this.isRateManagementEnabled;
    },

    /**
     * A message for empty state container
     * message string may change according to the conditional check
     * @returns {String} message for placeholder
     */
    placeholderText() {
      let message = "Please select a Property to manage Property Rate changes.";
      if (this.property && !this.isRateManagementEnabled)
        message = "Rate Management is not enabled for this Property";
      return message;
    },
  },
  beforeDestroy() {
    this.setDynamicRuntimeProperties(null);
    EventBus.$off("rateManagementByPropertyEvent");
  },
  methods: {
    ...mapMutations({
      setSpaceData: "revManStore/SET_SPACE_DATA",
    }),
    ...mapActions({
      fetchPropertySettings:
        "revManStore/fetchCurrentPropertyRatePlanConfiguration",
      setRestGridEnableAction: "reportStore/setRestGridEnableAction",
    }),
    _setSpaceData($event) {
      return;
      // temporary redirection as per request
      this.setSpaceData($event);
    },
    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },
    setHeight() {
      let header = 0;
      let stepper = 100;
      let tabs = 12; // 48
      let propertySelector = this.hasPropertySelector ? -53 : 36;
      let footer = 78;
      let heading = 48;
      let padding = 30;
      let notification = this.notification ? 47 : -12;
      this.reportHeight =
        window.innerHeight -
        notification -
        header -
        stepper -
        tabs -
        heading -
        footer -
        padding +
        propertySelector;
    },
    /**
     * Fetch Rate management configurations for property.
     * Note: as per hb implementation 'dynamicRun' query is needed for passing property key
     */
    triggerTableRefresh(columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.property);
      // Refresh table data
      EventBus.$emit("unit_edited");
    },
    /**
     * Fire configuration fetch and update table
     */
    async fetchProfileAndConfigurations() {
      if (!this.property) return;
      this.loading = true;
      try {
        this.triggerTableRefresh();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    updateSpaceGroupSettings(spacegroupIdHash, reqBody) {
      return api
        .put(
          this,
          api.getSpaceGroupRateManagementUrl(this.property, spacegroupIdHash),
          reqBody,
          "",
          false
        )
        .then(() => {
          this.showMessage();
          this.updateRowData(spacegroupIdHash, {
            spacegroup_rate_management_active: reqBody.active,
          });
        })
        .catch((err) => {
          if (err.status === 403) {
            this.updateRowData(spacegroupIdHash, {});
            this.showMessage(
              "error",
              "You don't have enough permissions to update this configuration."
            );
          } else this.showMessage("error");
        });
    },
    /**
     * Disable rate management for that spacegroup
     */
    async toggleProfileStatus(rowData) {
      // Status, Tokens and Labels
      const {
        spacegroup_id_hash,
        spacegroup_rate_plan_id,
        spacegroup_rate_management_active,
      } = rowData;
      let toggle = !Boolean(spacegroup_rate_management_active);
      let statusLabel = { true: "Enable", false: "Disable" }[toggle];

      if (!this.property && spacegroup_id_hash) return;

      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirm ${statusLabel}`,
          message: `Are you sure you want to <strong> ${statusLabel.toLowerCase()} the rate management profile </strong> you assigned to this space category`,
          resolver: `${statusLabel}`,
        })
        .catch(() => false);

      if (confirmed) {
        await this.updateSpaceGroupSettings(spacegroup_id_hash, {
          active: toggle,
          rate_plan_id: spacegroup_rate_plan_id,
        });
      }
      this.updateRowData(spacegroup_id_hash, {});
    },
    updateRowData(pKey, data = {}) {
      let node = null;
      if (this.gridApi) node = this.gridApi.getRowNode(pKey);
      if (node) node.setData({ ...node.data, ...data });
    },
    /**
     * Assign Rate management plan for a space group
     */
    async assignRatePlan(rowData) {
      const keyToLabelMapping = {
        spacegroup_spaces: {
          label: "Number of Spaces",
          type: "number",
        },
        spacegroup_occupancy: {
          label: "Occupancy",
          type: "percentage",
        },
        spacegroup_avg_rent_per_space: {
          label: "Avg Rent",
          type: "dollar",
        },
        spacegroup_avg_set_rate: {
          label: "Set Rate",
          type: "dollar",
        },
        spacegroup_avg_sell_rate: {
          label: "Sell Rate",
          type: "dollar",
        },
        spacegroup_variance_percentage: {
          label: "Variance (Rent/Sell)",
          type: "percentage",
        },
      };
      let {
        active,
        selected_profile: rate_plan_id,
        error,
      } = await this.$refs.assignRatePlan
        .show({
          size: rowData?.spacegroup_size,
          space_category: rowData?.spacegroup_category,
          active: !!rowData?.spacegroup_rate_management_active,
          info: reduceRowData(rowData, keyToLabelMapping),
          profiles: this.ratePlans,
          selected_profile: rowData.spacegroup_rate_plan_id,
          spacegroup_breakdown: rowData.spacegroup_amenities,
        })
        .catch(() => ({ error: true }));

      // In case of cancellation by user
      if (error) {
        if (rowData?.spacegroup_id_hash)
          this.updateRowData(rowData?.spacegroup_id_hash);
        return;
      }

      await this.updateSpaceGroupSettings(rowData.spacegroup_id_hash, {
        active,
        rate_plan_id,
      });
      // Update row data internally
      this.updateRowData(rowData.spacegroup_id_hash, {
        spacegroup_rate_management_active: active,
        spacegroup_rate_plan_id: rate_plan_id,
        spacegroup_rate_plan_name:
          this.ratePlans.find((plan) => plan.id === rate_plan_id)?.name ?? "",
      });
    },

    async managePromos(rowData) {
      const keyToLabelMapping = {
        spacegroup_spaces: {
          label: "Number of Spaces",
          type: "number",
        },
        spacegroup_occupancy: {
          label: "Occupancy",
          type: "percentage",
        },
        spacegroup_avg_rent_per_space: {
          label: "Avg Rent",
          type: "dollar",
        },
        spacegroup_avg_set_rate: {
          label: "Set Rate",
          type: "dollar",
        },
        spacegroup_avg_sell_rate: {
          label: "Sell Rate",
          type: "dollar",
        },
        spacegroup_variance_percentage: {
          label: "Variance (Rent/Sell)",
          type: "percentage",
        },
      };
      await this.$refs.managePromos.show({
        size: rowData?.spacegroup_size,
        space_category: rowData?.spacegroup_category,
        active: !!rowData?.spacegroup_rate_management_active,
        info: reduceRowData(rowData, keyToLabelMapping),
        spacegroup_breakdown: rowData.spacegroup_amenities,
        spacegroup_id_hash: rowData.spacegroup_id_hash,
      });
      if (rowData?.spacegroup_id_hash)
        this.updateRowData(rowData?.spacegroup_id_hash);
    },
    showMessage(type = "success", message = "") {
      let description = !message
        ? `${
            type == "success"
              ? "You have successfully updated"
              : "Unable to update"
          } Space Group settings.`
        : message;
      this.showMessageNotification({ type, description });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .boolean-icons {
  padding-left: 30px !important;
}

.profile-loader {
  margin-top: 6px;
}
</style>
